.FromToInfoCell {
  .FromToInfoCell__row {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    margin-bottom: 8px;
    max-width: 700px;
    position: relative;
    width: max-content;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .FromToInfoCell__row__value {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    .IconButton {
      margin-left: 4px;
    }
    .tags {
      margin-left: 4px;
    }
  }
  .FromToInfoCell__row__labelBlock {
    width: 61px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .FromToInfoCell__row__moreItems {
    position: absolute;
    right: 0;
    top: 24px;
    background: #ffffff;
    padding: 14px;
    z-index: 19;
    border: 1px solid #e2dff2;
    max-height: 300px;
    overflow: auto;
    border-radius: 16px;
    max-width: 446px;

    .FromToInfoCell__row__moreItems__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 620px;
      border-radius: 10px;
      border: 1px solid #efeef5;
      background: #fff;
      padding: 4px 8px;
      width: max-content;
      margin-bottom: 8px;
      .FromToInfoCell__addressItem {
        margin-bottom: 0 !important;
      }
      .tags {
        word-break: break-all;
        white-space: normal;
        margin-left: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .FromToInfoCell__row__moreItems__close {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }
    .FromToInfoCell__address {
      font-weight: 600;
    }
    .FromToInfoCell__row__moreItems__title {
      display: flex;
      align-items: center;
      color: #7a759a;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }
  .FromToInfoCell__tagsList {
    white-space: normal;
    word-break: break-all;
    display: flex;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    row-gap: 4px;
    max-width: 500px;

    .FromToInfoCell__tag__button {
      padding: 5px 8px;
      border-radius: 6px;
      background: rgb(173 162 247 / 36%);
      color: #4f3ad6;
      font-size: 14px;
      z-index: 1;
      margin-left: -12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      column-gap: 4px;
      cursor: pointer;
      line-height: 16px;
      padding-left: 11px;
      &.hide-btn {
        padding-left: 13px;
      }
    }
  }
  .FromToInfoCell__address {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .FromToInfoCell__row__label {
    font-size: 16px;
    font-weight: bold;
    margin-right: 4px;
    margin-left: 4px;
  }
  .FromToInfoCell__buttons {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  .FromToInfoCell__smallButton {
    border-radius: 6px;
    border: 1px solid rgba(94, 70, 244, 0.1);
    background: rgba(94, 70, 244, 0.1);
    padding: 4px;
    color: #4f3ad6;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    &:hover {
      background: rgba(79, 58, 214, 0.5);
    }
  }
  .FromToInfoCell__addressItem {
    display: flex;
    column-gap: 4px;
  }
  .FromToInfoCell__row.fullDetails {
    .FromToInfoCell__smallButton {
      display: none;
    }
    .FromToInfoCell__addressItem {
      border: 1px solid #efeef5;
      padding: 4px 8px;
      border-radius: 10px;
      margin-bottom: 4px;
      background: #ffffff;
    }
    .FromToInfoCell__row__moreItems {
      position: relative;
      padding: none;
      right: unset;
      max-width: unset;
      top: unset;
      padding: 0;
      border: none;
      .FromToInfoCell__row__moreItems__title {
        display: none;
      }
      .FromToInfoCell__address {
        font-weight: 500;
      }
    }
    .FromToInfoCell__row__moreItems {
      background: none;
      border-radius: 0;
    }
    .FromToInfoCell__row__moreItems__item {
      .FromToInfoCell__addressItem {
        padding: 0;
        border: none;
      }
    }
  }
  .FromToInfoCell__tagsList.margin-right {
    .AddressTag.last {
      margin-right: 60px;
    }
    .FromToInfoCell__tag__button {
      margin-left: -73px;
    }
  }
}
