.Checkbox {
  width: 16px;
  height: 16px;
  background: #efeef5;
  border: 1px solid #e2dff2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.isActive {
    background: #4f3ad6;
    border-color: #4f3ad6;
  }
  &.gray {
    &.isActive {
      background: #efeef5;
      border-color: #e2dff2;
    }
  }
}
