.RiskScoreChart {
  min-width: 220px;
  height: 220px;
  background: #ffffff;
  border: 1px solid #e2dff2;
  border-radius: 16px;
  position: relative;
  .chart-container {
    left: -16px;
    position: absolute;
    top: 18px;
  }
  .info-block {
    position: absolute;
    bottom: 41px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .percent {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }
    .risk-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-top: 2px;
    }
  }
}
