.FilterSelect {
  position: relative;
  width: 200px;

  .FilterSelect__input {
    border-radius: 8px;
    border: 1px solid #cfcfcf;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #7a759a;
    font-size: 14px;
    white-space: nowrap;
    justify-content: space-between;
    .FilterSelect__input__left {
      display: flex;
      align-items: center;
      span {
        margin-left: 8px;
      }
    }
  }
  .FilterSelect__options {
    position: absolute;
    top: 36px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .FilterSelect__option {
    padding: 3px 8px;
    cursor: pointer;
    background: #ffffff;
    font-weight: 500;
    &:hover {
      background: #e8e8e8;
    }
  }
}
