.BackButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4f3ad6;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
}
