@import '../../../assets/mixins.scss';

.SearchableSelectMultiple {
  position: relative;
  .SearchableSelectMultiple__input {
    background: #ffffff;
    border: 1px solid #e2dff2;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 56px;
    .left-block {
      width: calc(100% - 61px);
    }
    .counter {
      padding: 3px 8px;
      background: #4f3ad6;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      width: max-content;
    }
    .label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7a759a;
      position: absolute;
      top: 0;
      transform: translateY(15px);

      transition: all 0.1s linear;
    }
    .placeholder {
      opacity: 0;
      display: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7a759a;
      margin-bottom: 0;
      text-overflow: ellipsis;
      transition: all 0.1s linear;
      overflow: hidden;
      white-space: nowrap;
    }
    .SearchableSelectMultiple__rightBlock {
      display: flex;
      align-items: center;
    }
    .SearchableSelectMultiple__clearButton {
      padding: 8px;
      border-radius: 8px;
      transition: 0.2s linear all;
      &:hover {
        background: #d6d6d6;
      }
    }
    &.isOpen,
    &.hasSelectedItems {
      padding: 14px;
      .label {
        font-size: 12px;
        line-height: 16px;
        transform: translateY(7px);
      }
      .placeholder {
        opacity: 1;
        display: flex;
        column-gap: 4px;
        flex-wrap: wrap;
        max-width: 100%;
        row-gap: 4px;
        transform: translateY(7px);

        .item {
          display: flex;
          width: max-content;
          word-break: break-all;
          white-space: normal;
          align-items: center;
          padding: 2px 8px;
          background: rgba(94, 70, 244, 0.1);
          border: 1px solid rgba(94, 70, 244, 0.3);
          border-radius: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4f3ad6;
          .item__closeBtn {
            margin-left: 6px;
            margin-top: 2px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .SearchableSelectMultiple__options-block {
    position: absolute;
    width: 370px;
    top: 62px;
    background: #ffffff;
    border: 1px solid #e2dff2;
    border-radius: 16px;
    padding: 12px;
    z-index: 9;
    @media (max-width: 430px) {
      width: 100%;
    }
    .search-input {
      padding: 8px 12px;
      padding-left: 48px;
      .input-left-icon {
        top: 13px;
      }
      span {
        transform: translateY(10px);
      }
      &:focus-within > input,
      input:not(:placeholder-shown) {
        transform: translateY(3px);
      }
      &:focus-within > span,
      input:not(:placeholder-shown) + span {
        transform: translateY(-1px);
        font-size: 12px;
      }
      .action-button {
        &.with-icon {
          top: 16px;
        }
      }
    }
  }
  .options-list {
    margin-top: 4px;
    max-height: 320px;
    overflow: auto;
    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 8px;
      cursor: pointer;
      margin-bottom: 4px;
      border-radius: 8px;
      &:hover {
        background: rgba(112, 96, 221, 0.05);
      }

      &.active {
        background: rgba(79, 58, 214, 0.05);
      }
      .option-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #251f4b;
        word-break: break-all;
      }
      .Checkbox {
        min-width: 16px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #efeef5;
      border-radius: 8px;
    }
  }
  &.SearchableSelectMultiple__fullWidthOptions {
    .SearchableSelectMultiple__options-block {
      width: 100%;
    }
  }
  &.withClearButton {
    .left-block {
      width: calc(100% - 80px);
    }
  }
  &.withHiddenElements {
    height: 60px;
    .SearchableSelectMultiple__input {
      height: 100%;
      .left-block {
        height: 100%;
      }
      .placeholder {
        height: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #7a759a;
        margin-bottom: 0;
        text-overflow: ellipsis;
        transition: all 0.1s linear;
        overflow: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
        .item {
          white-space: nowrap;
          visibility: hidden;
          &:first-of-type {
            visibility: visible;
          }
        }
      }
    }

    .SearchableSelectMultiple__options-block {
      top: 63px !important;
    }
  }
}
