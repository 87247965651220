.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 3px;
  border: 2px solid #4f3ad6;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4f3ad6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-width-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  .lds-ring {
    width: 60px;
    height: 60px;
    div {
      width: 60px;
      height: 60px;
      border-width: 6px;
    }
  }
}

.full-page-loader {
  height: 100vh;
  display: flex;
  align-items: center;
}
