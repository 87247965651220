@import '../../../assets/mixins.scss';

.AmlReportAddressStatistics {
  .AmlReportAddressStatistics__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #7a759a;
    margin-bottom: 16px;
    max-width: 100%;
    word-break: break-all;
    @include tablet-max {
      text-align: center;
    }
  }
  .AmlReportAddressStatistics__content {
    display: flex;
    align-items: flex-start;
    @include tablet-max {
      flex-direction: column;
      align-items: center;
    }
  }
}
