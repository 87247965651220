.MainPageInfo {
  .MainPageInfo__row {
    display: flex;
  }
  .size-switcher {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .size-switcher__left,
    .size-switcher__right {
      height: 1px;
      background: #E2DFF2;
      width: 100%;
    }
    .size-switcher__center {
      color: #7A759A;
      padding: 0 41px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .icon {
        margin-left: 5px;
        margin-top: 1px;
      }
    }
  }
  @media (max-width: 961px) {
    .MainPageInfo__row {
      flex-direction: column;
    }
  }
}

.MainPageInfo.minimize-view {
  .Confirmations-block {
    display: none;
  }
  .MainAccounts {
    display: none;
  }
}
