.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .LoginPage__content {
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .LoginPage__title {
      width: 173px;
      height: 38px;
      background-image: url(../../../public/images/logo.svg);
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 16px;
      background-size: contain;
    }
    .input-container,
    .button {
      width: 100%;
      margin-top: 16px;
    }
    .error {
      margin: 10px 0;
      color: rgb(243, 35, 35);
    }
  }
}
