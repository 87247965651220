.ErrorPage {
  display: flex;
  justify-content: center;
  .error-container {
    width: 600px;
    border-radius: 16px;
    padding: 16px;
    font-size: 20px;
    background: #FFFFFF;
    margin-top: 150px;
  }
  .error-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}