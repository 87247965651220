.input-container {
  position: relative;
  border: 1px solid #e2dff2;
  border-radius: 16px;
  padding: 16px 20px;
  background: #ffffff;
  transition: 0.1s linear border;
  &.error-input {
    border-color: #ff0101;
    border-width: 2px;
  }
  .input-left-icon {
    position: absolute;
    left: 18px;
    top: 19px;
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    line-height: 24px;
    &::placeholder {
      opacity: 0;
    }
  }
  .InputLabel {
    position: absolute;
    top: 0;
    left: 20px;
    transition-duration: 200ms;
    pointer-events: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #7a759a;
    transform: translateY(15px);
  }
  .action-button {
    position: absolute;
    right: 20px;
    top: 17px;
    color: #4f3ad6;
    cursor: pointer;
    transition: 0.1s linear color;
    &:hover {
      color: #9186da;
    }
    &.disabled {
      color: #7a759a;
      cursor: default;
    }
    &.with-icon {
      top: 24px;
    }
  }
  &:focus-within > .InputLabel,
  input:not(:placeholder-shown) + .InputLabel {
    transform: translateY(8px);
    font-size: 12px;
  }
  &:focus-within > input,
  input:not(:placeholder-shown) {
    transform: translateY(7px);
  }
  &.with-action-btn {
    input {
      width: 85%;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .bottom-text {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #7a759a;
  }
  &.with-left-icon {
    padding-left: 48px;
    .InputLabel {
      left: 48px;
    }
  }
}
