table.Table {
  width: 100%;
  border-collapse: collapse;
}

.Table thead th {
  border-bottom: 1px solid #e2dff2;
}

.Table thead {
  background: #ffffff;
}

.Table th {
  padding: 12px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.06em;
  color: #7a759a;
  text-align: left;
  background: #ffffff;
  white-space: nowrap;
}

.Table td {
  padding: 12px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  vertical-align: middle;
  color: #251f4b;
}

.Table > tbody > tr:nth-child(2n + 1) {
  background-color: #f7f5fd;
}
.Table > tbody > tr:nth-child(2n) {
  background-color: #ffffff;
}

.Table {
  .d-flex-row {
    display: flex;
    align-items: center;
  }
  .nowrap {
    white-space: nowrap;
  }
  .trx-cell,
  .id-cell {
    white-space: nowrap;
    .container {
      display: flex;
      align-items: center;
    }
  }
  .id-cell {
    div {
      margin-right: 15px;
    }
  }
  .flex-end-cell {
    display: flex;
    justify-content: flex-end;
  }
  .currency {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 5px;
    }
  }
  tr.clicked {
    cursor: pointer;
    &:hover {
      background-color: rgb(238 238 238) !important;
    }
  }
  th.sorted-th {
    cursor: pointer;
    .container {
      display: flex;
      align-items: center;
    }
    .icon {
      min-width: 16px;
      min-height: 16px;
      height: 16px;
      width: 16px;
      margin-left: 3px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      &.icon-arrows {
        background-image: url(../../../public/images/icons/sort-arrows.svg);
      }
      &.icon-arrows-up {
        background-image: url(../../../public/images/icons/sort-arrows-up.svg);
      }
      &.icon-arrows-down {
        background-image: url(../../../public/images/icons/sort-arrows-down.svg);
      }
    }
  }
  .childs-operations {
    display: flex;
    align-items: center;
    .number {
      background: rgba(79, 58, 214, 0.1);
      border-radius: 6px;
      line-height: 24px;
      color: #4f3ad6;
      padding: 0 7px;
      font-weight: 600;
      margin-right: 7px;
      white-space: nowrap;
    }
    .icon-container.upside-down {
      transform: rotate(180deg);
    }
  }
  .child-indicator {
    background: #4f3ad6;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
  tr.childs-opened {
    background: rgba(79, 58, 214, 0.03) !important;
    .childs-operations {
      .number {
        background: #4f3ad6;
        color: #ffffff;
      }
    }
  }

  .fixed-col {
    cursor: pointer;
    width: 1%;
    vertical-align: middle;
  }
  .date-row td {
    color: #7a759a;
    background: #e4e1f4;
  }
}

.Table-container.rounded {
  background: #ffffff;

  .Table {
    border-collapse: separate;
    border-spacing: 0;
    thead {
      background: #ffffff;
    }
    th {
      background: none;
    }
    thead {
      th {
        border-top: 1px solid #e2dff2;
        &:first-of-type {
          border-left: 1px solid #e2dff2;
          border-top-left-radius: 16px;
        }
        &:last-of-type {
          border-right: 1px solid #e2dff2;
          border-top-right-radius: 16px;
        }
      }
    }
    tbody {
      tr {
        td:first-of-type {
          border-left: 1px solid #e2dff2;
        }
        td:last-of-type {
          border-right: 1px solid #e2dff2;
        }
      }
    }
    tbody {
      tr:last-of-type {
        td {
          border-bottom: 1px solid #e2dff2;
        }
        td:first-of-type {
          border-bottom-left-radius: 16px;
        }
        td:last-of-type {
          border-bottom-right-radius: 16px;
        }
      }
    }
  }
}

.Table__loader-container {
  min-height: calc(70vh - 50px);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  .lds-ring {
    width: 100px;
    height: 100px;
    div {
      width: 100px;
      height: 100px;
      border-width: 9px;
    }
  }
}

.Table-block {
  position: relative;
  @media (max-width: 800px) {
    max-width: 100%;
    overflow: auto;
    .Table {
      th {
        white-space: nowrap;
      }
      td {
        word-break: normal;
        font-size: 14px;
        padding: 5px 8px;
      }
    }
  }
}

.TableFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .right-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    .right-block {
      justify-content: center;
    }
  }
  .customize-fields {
    display: flex;
    align-items: center;
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid #e2dff2;
    order: 2;
    .settings-button {
      background: rgba(79, 58, 214, 0.1);
      border-radius: 4px;
      cursor: pointer;
      padding: 6px;
    }
    @media (max-width: 1000px) {
      border-left: none;
    }
  }
}

.TablePagination {
  margin-top: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 500px) {
    justify-content: center;
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;
    li {
      list-style: none;
      border: 1px solid #e2dff2;
      border-radius: 4px;
      color: #827e9c;
      background: #f7f5fd;
      margin-right: 5px;
      cursor: pointer;
      @media (max-width: 500px) {
        margin-right: 2px;
      }
      a {
        width: 100%;
        height: 100%;
        padding: 6px 7px;
        display: block;
      }
      &:hover {
        background: #efeaff;
      }
      &.selected {
        background-color: #4f3ad6;
        border-color: #4f3ad6;
        color: #ffffff;
        &:hover {
          background-color: #4f3ad6;
        }
      }
      .next-button,
      .previous-button {
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }
}

.TableTotals {
  display: table-row-group;
  background: #ffffff;
  td {
    font-weight: 700;
    border-bottom: 1px solid #e2dff2;
  }
}

.Table__small-text {
  font-size: 12px;
  color: #777777;
  line-height: 14px;
}
