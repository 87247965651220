@import '../../assets/mixins.scss';

.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  z-index: 50;
  .modal-background {
    background: #251f4b;
    opacity: 0.7;
    width: 100%;
    position: absolute;
    height: 100%;
  }
  .modal {
    z-index: 51;
    margin-top: 80px;
    width: 520px;
    background: #ffffff;
    height: max-content;
    padding: 24px;
    &.rounded {
      border-radius: 16px;
      border: 1px solid #e2dff2;
    }
  }
}

.ModalTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #251f4b;
  align-items: center;
  .icon {
    cursor: pointer;
  }
}

.ModalButtons {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .button:first-of-type {
    margin-right: 8px;
  }
  @include tablet-max {
    width: 100%;
    .button {
      flex: 1;
    }
  }
}

.ModalFields {
  margin-top: 26px;
  .input-container,
  .SearchableSelect {
    margin-bottom: 16px;
  }
}

.ModalError {
  color: rgb(248, 35, 35);
  margin: 10px 0;
}
