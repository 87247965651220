.SystemAddressesFilters {
  margin-bottom: 16px;
  .SystemAddressesFilters__filters {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
  }
  .SystemAddressesFilters__filters__inputs {
    flex: 1;
  }
  .SystemAddressesFilters__filters__currenciesSelect {
    flex: 1;
  }
  .SystemAddressesFilters__filters__buttons {
    display: flex;
    column-gap: 8px;
    .button {
      max-height: 55px;
    }
  }
  .SystemAddressesFilters__filters__checkbox {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 8px;
    .Checkbox {
      margin-left: 8px;
    }
  }
}
