.AddressTag {
  padding: 4px 8px;
  border-radius: 6px;
  background: #efeef5;
  font-size: 14px;
  font-style: normal;
  width: max-content;
  font-weight: 400;
  line-height: 16px;
  z-index: 2;
  letter-spacing: -0.48px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  border: 1px solid #c3c3c3;

  &.invoiceTag {
    border: 1px solid #3a78d6;
    background: #3a78d6;
    color: #ffffff;
  }
}
