.ActivityPage {
  padding: 24px 40px 40px 40px;
  @media (max-width: 961px) {
    padding: 10px;
  }
  .ActivityPage__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7a759a;
    @media (max-width: 961px) {
      text-align: center;
    }
  }
  .ActivityPage-table-country-name {
    margin-right: 3px;
  }
}
