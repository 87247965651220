@import './mixins.scss';

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'SpaceMono';
  src: url('./fonts/SpaceMono-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-display: swap;

  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #251f4b;
  font-size: 16px;
  background: #efeef5;
  * {
    box-sizing: border-box;
  }
}

.PageContainer {
  padding: 24px 40px 40px 40px;
  @media (max-width: 961px) {
    padding: 10px;
  }
}

.PageTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.ContentBlock {
  background: #ffffff;
  padding: 24px;
  border: 1px solid #e2dff2;
  border-radius: 16px;
}

.ContentBlockTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #7a759a;
  margin-bottom: 16px;
}
