.PeriodFilters-block {
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.PeriodFilters-date {
  align-self: flex-start;
  margin-left: 4px;
  background: #f7f5fd;
  border: 1px solid #e2dff2;
  border-radius: 4px;
  padding: 6px 7px;
  color: #7a759a;
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  @media (max-width: 1000px) {
    flex-direction: column;
    white-space: nowrap;
  }
}

.PeriodFilters-date--active {
  background-color: #4f3ad6;
  border-color: #4f3ad6;
  color: #ffffff;
}

.PeriodFilters {
  display: flex;
  align-items: center;
}

.PeriodFilters .datepicker-container {
  position: absolute;
  right: 0;
  z-index: 9;
  top: 35px;
  * {
    box-sizing: content-box;
  }
  .react-datepicker {
    background: #f7f5fd;
    box-shadow: 0px 12px 44px rgba(37, 31, 75, 0.25);
    border-radius: 12px;
    padding: 16px 0px;
    border: 0;
  }
  .react-datepicker__navigation {
    top: 17px;
    transition: 0.2s linear background;
    &:hover {
      background: rgba(42, 39, 59, 0.07);
      border-radius: 8px;
      .react-datepicker__navigation-icon {
        &::before {
          border-color: #4f3ad6;
        }
      }
    }
  }
  .react-datepicker__navigation--previous {
    left: 16px;
  }
  .react-datepicker__navigation--next {
    right: 16px;
  }
  .react-datepicker__navigation-icon {
    &::before {
      border-color: #191a2c;
      width: 6px;
      height: 6px;
      border-width: 2px 2px 0 0;
      top: 10px;
      left: -3px;
      transition: 0.2s linear border-color;
    }
  }
  .react-datepicker__header {
    background: #f7f5fd;
    border-bottom: 0;
  }
  .react-datepicker__current-month {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #251f4b;
  }
  .react-datepicker__day-names {
    border-top: 1px solid #e5e5e6;
    margin-top: 20px;
  }
  .react-datepicker__day-name {
    padding: 15px 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #757680;
    @media (max-width: 550px) {
      font-size: 14px;
      padding: 6px;
    }
  }
  .react-datepicker__day {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    padding: 16px 15px;
    &:hover {
      background: rgba(79, 58, 214, 0.07);
      color: #4f3ad6;
    }
    @media (max-width: 550px) {
      font-size: 14px;
      padding: 6px;
    }
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    background: #4f3ad6;
  }
}

.PeriodFilters.big-view {
  @media screen and (min-width: 1500px) {
    display: flex;
    align-items: center;
    border: 1px solid rgba(94, 70, 244, 0.2);
    border-radius: 12px;
    flex-wrap: wrap;
    width: max-content;
    .PeriodFilters-date {
      background: rgba(94, 70, 244, 0.1);
      padding: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f3ad6;
      border-radius: 0;
      cursor: pointer;
      white-space: nowrap;
      margin-left: 0;
      transition: 0.1s linear background;
      &:first-of-type {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      &:last-of-type {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
      &:hover {
        background: rgba(94, 70, 244, 0.2);
      }
      &--active {
        background: #4f3ad6;
        color: #ffffff;
        &:hover {
          background: #4f3ad6;
        }
      }
    }
  }
}
