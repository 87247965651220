.TotalBlock {
  margin-bottom: 16px;
  .head-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .title {
      font-weight: 400;
      color: #7a759a;
    }
    @media (max-width: 650px) {
      flex-direction: column;
    }
  }
  &.minimize-view {
    .total-list {
      .TotalItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        border-radius: 12px;
        .title {
          font-size: 15px;
          line-height: 24px;
        }
        .value {
          font-size: 16px;
          line-height: 24px;
        }
        .additional-value {
          font-size: 12px;
        }
        .value-updates {
          display: none;
        }
        .TotalItem__updates-time-bottom {
          display: none;
        }
      }
    }
  }
}

.total-list {
  display: flex;
  justify-content: space-between;
  .TotalItem {
    flex: 1;
    margin-right: 15px;
    border-radius: 16px;
    padding: 16px 24px;

    &:last-of-type {
      margin-right: 0;
    }
    &.deep-blue {
      background: linear-gradient(99.94deg, #4f3ad6 0%, #6c3ad6 100%);
    }
    &.blue {
      background: #3ca8dd;
    }
    &.red {
      background: linear-gradient(99.94deg, #cc263a 0%, #d0234c 100%);
    }
    &.green {
      background: linear-gradient(99.94deg, #1ab083 0%, #3ad694 100%, #17af6f 100%);
    }
    &.yellow {
      background: #e3c22a;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
    }
    .value-block {
      display: flex;
      align-items: flex-start;
      .value {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #ffffff;
        &.withUpdatesAdditionalValues {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-top: 5px;
        }
        .value-updates {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-transform: none;
        }
      }
      .additional-values-block {
        display: flex;
        align-items: flex-start;
        margin-left: 8px;
        margin-top: 5px;
        .additional-value {
          color: #ffffff;
          .additional-value__value {
            display: flex;
            align-items: center;
          }
          .value-updates {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-left: 15px;
          }
          .icon {
            margin-right: 4px;
          }
          &:first-of-type {
            margin-right: 8px;
          }
        }
      }
    }
    .TotalItem__updates-time-bottom {
      color: #ffffff;
      font-size: 12px;
      margin-top: 4px;
      font-family: 'SpaceMono';
      letter-spacing: -0.72px;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    .TotalItem {
      margin-right: 0;
      margin-bottom: 8px;
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .additional-values-block {
      .additional-value {
        margin-right: 0 !important;
        margin-bottom: 2px;
      }
    }
  }
}
