.SystemAddressesTable__buttons {
  display: flex;
  column-gap: 8px;
}
.SystemAddressesTable {
  .Table-block {
    max-width: 100%;
    overflow-x: auto;
  }
  .SystemAddressesTable__address {
    display: flex;
    .IconButton {
      margin-left: 4px;
    }
  }
}
