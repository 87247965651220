.select select {
  padding: 8px;
  border: none;
  background: #EFEEF5;
  color: #7A759A;
  cursor: pointer;
  font-weight: 700;
  border-radius: 6px;
  option {
    line-height: 50px;
  }
}