@import '../../assets/mixins.scss';

.AmlAddressPage {
  padding: 24px 40px 40px 40px;
  @include tablet-max {
    padding: 10px;
  }
  .AmlAddressPage__content {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #e2dff2;
    border-radius: 16px;
  }
  .AmlAddressPage-back-button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4f3ad6;
    cursor: pointer;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 8px;
    }
  }
  .AmlAddressPage__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #251f4b;
    margin-top: 8px;
    margin-bottom: 16px;
    word-break: break-all;
    span {
      @include tablet-max {
        display: block;
        color: #404040;
        font-size: 14px;
      }
    }
  }
  .AmlAddressPage__info {
    .AmlAddressPage__info-block {
      margin-top: 16px;
      .AmlAddressPage__info-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #251f4b;
        margin-bottom: 4px;
      }
      .AmlAddressPage__info-value {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #251f4b;
      }
    }
  }
}
.no-data-addresses-page {
  height: 100%;
  min-height: calc(70vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e2dff2;
  border-radius: 16px;
  margin-top: 16px;
  .no-data-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #251f4b;
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .no-data-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7a759a;
    white-space: nowrap;
  }
}
