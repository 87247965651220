.notifications-list {
  min-width: 300px;
  max-width: 420px;
  position: fixed;
  top: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  .notifications-item {
    background: #f7f5fd;
    border: 1px solid #e2dff2;
    border-radius: 16px;
    padding: 8px 16px;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .IconButton {
      &:not(:hover) {
        background: none;
      }
    }
    &.success {
      background: #2ab02a;
      color: #ffffff;
    }
    &.error {
      background: #f82020;
      color: #ffffff;
    }
  }
}
