button.button {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  padding: 8px;
  border-radius: 4px;
  transition: 0.2s linear background;
  display: flex;
  align-items: center;
  background: rgba(37, 31, 75, 0.05);
  justify-content: center;
  color: #251f4b;
  border: 1px solid rgba(37, 31, 75, 0.05);
  &:hover {
    background: #dedaf3;
  }
  &.primary {
    background: #4f3ad6;
    color: #ffffff;
    border: none;
    &:hover {
      background: #5d4bd3;
    }
  }
  &.cancel {
    background: #e02138;
    color: #ffffff;
    border: none;
    &:hover {
      background: #bb3040;
    }
  }
  &.primary-rounded {
    background: #4f3ad6;
    color: #ffffff;
    border: none;
    &:hover {
      background: #5d4bd3;
    }
    border-radius: 12px;
  }
  &.secondary-2 {
    background: rgba(94, 70, 244, 0.1);
    color: #4f3ad6;
  }
  .icon {
    margin-left: 8px;
    margin-right: 13px;
  }
  &.big {
    padding: 20px 32px;
    font-weight: 700;
    border-radius: 16px;
  }
  &.small {
    padding: 4px 12px;
  }
  &.normal-xl {
    padding: 12px 24px;
    font-weight: 700;
    border-radius: 16px;
  }
  &.extra-small {
    padding: 2px 4px;
    font-size: 14px;
    line-height: 18px;
  }
  &:disabled {
    background: #d3cfed;
    color: #9993c6;
    cursor: default;
    &:hover {
      background: #d3cfed;
    }
  }
  .button-loader {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
}
