@import '../../../assets/mixins.scss';

.AmlSignalsStatistics {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  width: 100%;
  justify-content: flex-start;
  .AmlSignalsStatistics__block {
    margin-left: 24px;
    flex: 1;
    max-width: 272px;
    .AmlSignalsStatistics__block-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #7a759a;
    }
    .no-data {
      margin-top: 9px;
      font-size: 14px;
      color: #7a759a;
    }
  }
  @include tablet-max {
    flex-direction: column;
    margin-top: 20px;
    .AmlSignalsStatistics__block {
      margin-bottom: 16px;
      width: 100%;
      max-width: unset;
      margin-left: 0;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.AmlSignalsStatisticItem {
  margin-top: 14px;
  width: 100%;
  .progress-bar {
    width: 100%;
    border-radius: 8px;
    height: 10px;
    padding: 2px 3px;
    margin-bottom: 4px;
    .progress-line {
      height: 6px;
      border-radius: 6px;
    }
  }
  &.high {
    .progress-bar {
      background: #e021372e;
    }
    .progress-line {
      background: #e02138;
    }
  }
  &.medium {
    .progress-bar {
      background: #e0872136;
    }
    .progress-line {
      background: #e08821;
    }
  }
  &.low {
    .progress-bar {
      background: #17aa7428;
    }
    .progress-line {
      background: #17aa75;
    }
  }
  .info-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #251f4b;
    .value {
      font-weight: 700;
      .percent-block {
        margin-left: 4px;
        padding: 2px 4px;
        background: rgba(37, 31, 75, 0.05);
        border: 1px solid rgba(37, 31, 75, 0.05);
        border-radius: 6px;
      }
    }
  }
}

.AmlSignalsStatistics.AmlSignalsStatistics__report-page {
  .AmlSignalsStatisticItem {
    .info-block {
      .value {
        font-weight: 500;
        .percent-block {
          border: none;
          background: none;
          &::before {
            content: '(';
          }
          &::after {
            content: ')';
          }
        }
      }
    }
  }
}
