$header-height: 124px;

@mixin tablet-max {
  @media (max-width: 961px) {
    @content;
  }
}

@mixin mobile-max {
  @media (max-width: 560px) {
    @content;
  }
}
