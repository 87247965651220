.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  z-index: 50;
  .modal-background {
    background: #251F4B;
    opacity: 0.7;
    width: 100%;
    position: absolute;
    height: 100%;
  }
  .modal {
    z-index: 51;
    margin-top: 80px;
    width: 100%;
  }
}

.CustomizeColumnsModal {
  display: flex;
  justify-content: center;
  .CustomizeColumnsModal-container {
    width: 511px;
    background: #FFFFFF;
    padding: 24px;
    @media (max-width: 511px) {
      width: 100%;
    }
  }
  .modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
    .icon {
      cursor: pointer;
    }
  }
  .modal-content {
    .columns-container {
      background: #EFEEF5;
      border-radius: 16px;
      padding: 8px;
      user-select: none;
      .col-item {
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 12px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .left-block {
          display: flex;
          align-items: center;
          .field-name {
            font-size: 14px;
            line-height: 28px;
            margin-left: 12px;
          }
        }
      }
    }
  }
}