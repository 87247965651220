.IconButton {
  padding: 5px;
  background: rgba(79, 58, 214, 0.05);
  border-radius: 6px;
  width: max-content;
  cursor: pointer;
  transition: 0.2s linear background;
  &:hover {
    background: rgba(79, 58, 214, 0.5);
  }
  display: flex;
  align-items: center;
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4f3ad6;
  }
}
