.TxInfoPage {
  .operationId {
    display: flex;
  }
  .ProcessingTable__statusCell {
    .ProcessingTable__address {
      display: flex;
      column-gap: 4px;
      color: #303030;
      white-space: nowrap;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .ProcessingTable__status {
    &.bold {
      font-weight: bold;
    }
  }
  .Table-block {
    overflow-x: auto;
    min-height: 70vh;
    max-height: 70vh;
    &.loading {
      min-height: auto;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 20;
    }
    .new-transaction {
      width: 8px;
      height: 8px;
      background: #e02138;
      border-radius: 50px;
      margin-right: 16px;
    }
    .operationId {
      display: flex;
    }
  }
}
