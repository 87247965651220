@import '../../../../assets/mixins.scss';

.ConfirmModal {
  .ConfirmModal__title {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #251f4b;
    align-items: center;
    .icon {
      cursor: pointer;
    }
  }
  .ConfirmModal__description {
    margin-top: 18px;
  }
  .ConfirmModal__buttons {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .button:first-of-type {
      margin-right: 8px;
    }
    @include tablet-max {
      width: 100%;
      .button {
        flex: 1;
      }
    }
  }
}
