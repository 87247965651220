.MainPage {
  padding: 24px 40px 40px 40px;
  @media (max-width: 961px) {
    padding: 10px;
  }
  .Table-block {
    overflow-x: auto;
    min-height: 70vh;
    max-height: 70vh;
    &.loading {
      min-height: auto;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 20;
    }
    .new-transaction {
      width: 8px;
      height: 8px;
      background: #e02138;
      border-radius: 50px;
      margin-right: 16px;
    }
    .operationId {
      display: flex;
    }
  }
  .ProcessingTable__statusCell {
    .ProcessingTable__address {
      display: flex;
      column-gap: 4px;
      color: #303030;
      white-space: nowrap;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .ProcessingTable__status {
    &.bold {
      font-weight: bold;
    }
  }
}
.MainPage__operations {
  margin-top: 24px;
}

.OperationsFilter__tabs {
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    order: 2;
    .OperationsFilter__tab {
      flex: 1;
      margin: 0;
      text-align: center;
      margin-bottom: 2px;
    }
  }
}

.OperationsFilter__tab {
  margin-right: 24px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  color: #7a759a;
  letter-spacing: -0.06em;
}

.OperationsFilter__tab:not(.OperationsFilter__tab--active):hover {
  color: #251f4b;
}

.OperationsFilter__tab--active {
  color: #4f3ad6;
  border-bottom: 1px solid #4f3ad6;
  cursor: default;
}
