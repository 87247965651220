
.ExplorerPage {
  padding: 24px 40px 40px 40px;
  @media (max-width: 961px) {
    padding: 10px;
  }
  .title {
    font-weight: 400;
    line-height: 24px;
    color: #7A759A;
    margin-bottom: 8px;
  }
  .search-input-container {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .input-container {
      width: 670px;
      @media (max-width: 961px) {
        width: 100%;
      }
    }
    @media (max-width: 961px) {
      flex-direction: column;
    }
    .founded-operations,
    .nothing-found {
      font-weight: 400;
      font-size: 16px;
      margin-left: 12px;
      line-height: 24px;
      padding: 16px 24px;
      border-radius: 16px;
      @media (max-width: 961px) {
        margin-top: 8px;
      }
    }
    .founded-operations {
      color: #4F3AD6;
      background: rgba(79, 58, 214, 0.1);
    }
    .nothing-found {
      background: rgba(224, 33, 56, 0.1);
      color: #E02138;
    }
  }
  @media (min-width: 1730px) {
    .search-input-container {
      margin-bottom: -27px;
    }
    .title {
    }
  }
}