
.toggleWrapper {
  width: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  input {
    &.mobileToggle {
      opacity: 0;
      position: absolute;
      cursor: pointer;
      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: .4s ease;
        height: 16px;
        width: 28px;
        background: #EFEEF5;
        border-radius: 60px;
        &:before {
          content: "";
          position: absolute;
          display: block;
          transition: .2s cubic-bezier(.24, 0, .5, 1);
          height: 16px;
          width: 28px;
          top: 0;
          left: 0;
          cursor: pointer;
          border-radius: 40px;
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          transition: .35s cubic-bezier(.54, 1.60, .5, 1);
          background: #7A759A;
          opacity: 0.5;
          height: 12px;
          width: 12px;
          cursor: pointer;
          top: 2px;
          left: 2px;
          border-radius: 40px;
        }
      }
      &:checked {
        & + label:before {
          background: #4F3AD6;
          transition: width .2s cubic-bezier(0, 0, 0, .1);
        }
        & + label:after {
          left: 14px;
          opacity: 1;
          background: #FFFFFF;
          top: 2px;
        }
      }
    }
  }
}