@import '../../assets/mixins.scss';

header {
  height: $header-height;
  background: #ffffff;
  position: relative;
  .Header__menu-icon {
    display: none;
  }
  .Header-top {
    height: 88px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    justify-content: space-between;
    padding-right: 37px;
  }
  .Header__left-block {
    display: flex;
    align-items: flex-end;
    .Header__logo {
      width: 173px;
      height: 38px;
      background-image: url(../../../public/images/logo.svg);
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 16px;
      background-size: contain;
    }
    .Header__client-id {
      font-size: 400;
      padding-bottom: 6px;
    }
  }
  .Header__right-block {
    display: flex;
    align-items: center;
  }
  .Header__logout-btn {
    padding: 16px 24px;
    background: #efeef5;
    border-radius: 16px;
    color: #251f4b;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    margin-left: 20px;
    @include tablet-max {
      padding: 12px;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .currency {
    display: flex;
    align-items: center;
    .title {
      color: #7a759a;
      margin-right: 10px;
    }
  }
  .Header-menu {
    .Header-menu__closeBtn {
      display: none;
    }
    .Header-menu__list {
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 37px;
      column-gap: 24px;
    }
    a {
      text-decoration: none;
    }
    .MenuItem {
      font-weight: 500;
      font-size: 16px;
      color: #7a759a;
      padding-bottom: 14px;
      &.active {
        color: #4f3ad6;
        border-bottom: 2px solid #4f3ad6;
      }
    }
  }

  @include tablet-max {
    .Header__menu-icon {
      display: block;
      position: absolute;
      left: 15px;
      top: 15px;
    }
    .Header-top {
      padding-left: 15px;
      padding-right: 15px;
      flex-direction: column;
      height: auto;
      padding-bottom: 10px;
      justify-content: center;
    }
    .Header__left-block {
      align-items: center;
      flex-direction: column;
      .Header__logo {
        margin-bottom: 5px;
        margin-top: 10px;
      }
      .Header__client-id {
        font-size: 12px;
        text-align: center;
      }
    }
    .Header-menu__container {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      background: rgba(0, 0, 0, 0.435);
      opacity: 0;
      z-index: -1;
      transition: all 0.1s linear;
      &.isOpened {
        opacity: 1;
        z-index: 998;
        .Header-menu {
          transform: translateX(0);
        }
      }
    }
    .Header-menu {
      position: fixed;
      top: 0;
      width: 300px;
      padding-top: 50px;
      background: #ffffff;
      height: 100vh;
      z-index: 999;
      transform: translateX(-300px);
      transition: all 0.1s linear;
      .Header-menu__closeBtn {
        display: block;
        position: absolute;
        right: 16px;
        top: 16px;
      }
      .Header-menu__list {
        flex-direction: column;
        a {
          width: 100%;
        }
        .MenuItem {
          padding-bottom: 8px;
          margin-bottom: 10px;
        }
      }
    }
  }
  &.with-updates-block {
    .Header__update-block {
      display: flex;
      padding: 10px 12px 10px 16px;
      border: 1px solid rgba(94, 70, 244, 0.1);
      border-radius: 16px;
      column-gap: 8px;
      margin-left: 16px;
      align-items: center;
      .Header__update-block__content {
        .Header__update-block__top {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #251f4b;
        }
        .Header__update-block__bottom {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #7a759a;
        }
      }
      .Header__update-block__btn {
        padding: 4px 8px;
        border: 1px solid rgba(94, 70, 244, 0.1);
        border-radius: 8px;
        display: flex;
        column-gap: 4px;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #251f4b;
        cursor: pointer;
      }
    }
    @media (max-width: 1420px) {
      height: auto;
      .Header-top {
        height: auto;
        align-items: flex-start;
        @include tablet-max {
          align-items: center;
        }
      }
      .Header__left-block {
        margin-top: 20px;
      }
      .Header__right-block {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 5px;
        @include tablet-max {
          justify-content: center;
        }
      }
      .Header__update-block {
        order: 3;
      }
    }
  }
}
