@import '../../assets/mixins.scss';

.TotalBalances {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  flex: 1;
  .title {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #7a759a;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .refresh-bth {
      margin-left: 4px;
      cursor: pointer;
      background: rgba(94, 70, 244, 0.1);
      border-radius: 6px;
      height: 32px;
      width: 32px;
      padding: 6px;
    }
  }
  .balances-list {
    display: flex;
    flex-wrap: wrap;
    .balance-item {
      background: #f7f5fe;
      border-radius: 8px;
      flex: 1;
      padding: 16px;
      margin-right: 8px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:last-of-type {
        margin-right: 0;
      }
      .balance-item__top {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .currency-title {
          margin-right: 4px;
          line-height: 24px;
          letter-spacing: -0.04em;
          color: #7a759a;
          white-space: nowrap;
        }
      }
      .balance-item__amount {
        font-size: 20px;
        line-height: 25px;
        .amount {
          font-weight: 700;
          margin-right: 5px;
        }
        .currency {
          font-weight: 500;
        }
      }
      .balance-item__usd-eur-amounts {
        span {
          color: #7a759a;
          &:first-of-type {
            margin-right: 4px;
          }
        }
        margin-bottom: 8px;
      }
      .withdrawal-button-container {
        .button {
          width: 100%;
        }
      }
      &.disabled {
        .balance-item__amount {
          color: #7a759a;
        }
      }
      .balance-item__updates {
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 4px;
        .balance-item__updates__amount {
          color: #17aa75;
          font-weight: 700;
          &.isNegative {
            color: #e02138;
          }
        }
      }
    }
  }
  @media (max-width: 961px) {
    padding: 12px;
    .balances-list {
      .balance-item {
        padding: 12px;
        width: 100%;
        flex: auto;
        .balance-item__amount {
          font-size: 14px;
        }
      }
    }
  }
}

.TotalBalances.minimize-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: none;
  padding: 0;
  .title {
    margin-bottom: 0;
    color: #251f4b;
    flex-wrap: wrap;
    column-gap: 6px;
    justify-content: center;
    .TotalBalancesLastUpdate {
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #7a759a;
      font-size: 14px;
    }
    @include mobile-max {
      flex-direction: column;
    }
  }
  .balances-list {
    margin-left: 20px;
    .balance-item {
      flex-direction: row;
      display: flex;
      margin-bottom: 0;
      flex: none;
      align-items: flex-start;
      padding: 0;
      background: none;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
      .balance-item__top {
        margin-bottom: 0;
        .currency-title {
          order: 2;
          margin-left: 4px;
        }
        .icon {
          order: 1;
        }
      }
      .withdrawal-button-container {
        display: none;
      }
      .balance-item__usd-eur-amounts {
        font-size: 14px;
        margin-bottom: 0;
        margin-left: 4px;
      }
      .balance-item__amount {
        font-size: 16px;
        margin-left: 4px;
        line-height: 23px;
        .amount {
          font-weight: 500;
          margin-right: 3px;
        }
      }
      .balance-item__updates {
        display: none;
      }
    }
  }
  @media (max-width: 961px) {
    flex-direction: column;
    align-items: center;
    .balances-list {
      margin: 0;
      .balance-item {
        margin: 0;
        justify-content: center;
        align-items: flex-start;
        > div {
          display: flex;
          align-items: flex-start;
          width: 100%;
        }
        .balance-item__top {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          .currency-title {
            text-align: end;
            font-size: 12px;
          }
        }
        .amounts-container {
          width: 60%;
        }
        .balance-item__amount {
          font-size: 14px;
          .currency {
            word-break: normal;
          }
        }
      }
    }
  }
}
